@import '../../../../style/flex.scss';

.change-orderno-modal {
  .modal-screen {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.4);
    z-index: 10;
  }
  .modal-content {
    width: 469px;
    background-color: #fff;
    border-radius: 6px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 11;
    padding-bottom: 30px;
    .content-title {
      font-size: 16px;
      color: #333;
      border-bottom: 1px solid #eee;
      padding: 19px 0;
      padding-left: 36px;
    }
    .content-input {
      margin-top: 21px;
      .input-name {
        font-size: 14px;
        color: #333;
        margin-left: 36px;
      }
      .input-node {
        font-size: 14px;
        color: #333;
        text-indent: 16px;
        width: 279px;
        border: 1px solid #ccc;
        border-radius: 6px;
        padding: 5px 0;
        margin-left: 40px;
      }
    }
    .content-btns {
      margin-top: 30px;
      justify-content: flex-end;
      .btns-item {
        width: 90px;
        text-align: center;
        border-radius: 4px;
        box-sizing: border-box;
        padding: 3px 0;
        cursor: pointer;
      }
      .btn-l {
        border: 1px solid #979797;
        color: #999;
        margin-right: 12px;
      }
      .btn-r {
        background-color: #ff5156;
        border: 1px solid #ff5156;
        color: #fff;
        margin-right: 20px;
      }
    }
  }
}
