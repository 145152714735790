// $red: #fd5356 ;
// $lightred: #fffdfd;
@import './options.scss';


#toggleSidebarIcon {
    position: fixed;
    left: 20px;
    top: 20px;
    z-index: 2000;
    width: 20px;
    height: auto;

    @media print {
        display: none;
    }
}

