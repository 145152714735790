@import './options.scss';

.tuan {
    padding: 40px 0 20px;

    @media (min-width: 1200px) {
        width: 900px
    }

    @media (min-width: 756px) {
        width: 600px
    }

    @media (max-width: 756px) {
        width: 90vw
    }

    &__panel {
        background: #fff;
        border-radius: 10px;
        padding: 15px 12px;
        margin-bottom: 20px;
    }

    &__top {
        text-shadow: 0 2px 4px rgba(51, 51, 51, 0.50);
        font-size: 24px;
        color: #fff;
        margin-bottom: 1em;
    }

    &__content {
        max-width: 95vw;
        margin-left: auto;
        margin-right: auto;
        position: relative;

        &__shopinfo {
            display: flex;
            flex: 1;
            align-items: center;
            justify-content: flex-start;
            margin-bottom: 1em;
            padding-top: 15px;

            &__ava {
                width: 40px;
                height: 40px;
                margin-right: 10px;

                &__img {
                    width: 100%;
                    height: 100%;
                    border-radius: 50%;
                }
            }

            &__name {
                flex: 1;
                max-width: 10em;
                overflow: hidden;
                word-break: break-all;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        &__meta {
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            justify-content: flex-start;
            font-size: 12px;

            &__item {
                margin-right: 15px;
                height: 1.5em;
                border-radius: 1em;
                padding: 0 1em;
                background-color: #fff3e4;
                margin-bottom: 1em;

                &__text {
                    color: #ff8443;
                    font-size: 12px;
                }
            }
        }

        &__content {
            &__content-imgs {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-between;

                &__img {
                    width: 25vw;
                    height: 25vw;
                    margin-bottom: 10px;
                }
            }
        }

        &__goods {
            &__category {
                &::-webkit-scrollbar {
                    //    display: block;
                    height: 5px;

                    @media (max-width: 600px) {
                        height: 0
                    }
                }

                width: 100%;
                overflow-x: auto;
                margin-bottom: 20px;
                margin-top: 20px;
                padding-bottom: 5px;
                white-space: nowrap;
                height: 2.6rem;

                &__item {
                    display: inline-flex;
                    width: 6em;
                    position: relative;
                    font-size: 14px;

                    &_active {
                        font-weight: bold;
                        font-size: 18px;

                        &::after {
                            content: '';
                            position: absolute;
                            display: block;
                            height: 4px;
                            width: 1.4em;
                            background: rgb(255, 81, 86);
                            bottom: -3px;
                            left: 2.3em;
                            border-radius: 3px;
                            // margin: 0 auto;
                            // vertical-align:middle;
                        }
                    }
                }
            }
        }
    }

    &__order {
        &__title {
            padding: 1em;
            padding-left: 0;
            font-size: 12px;
            color: #9b9b9b;
            border-bottom: 1px #f4f4f4 solid;
        }

        &__item {
            margin-top: 20px;

            // padding-left: 12px;
            &__head {
                margin-bottom: 12px;

                &__ava {
                    width: 30px;
                    height: 30px;
                    margin-right: 6px;
                }

                &__name {
                    font-size: 14px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                    max-width: 6em;
                }

                &__showtime {
                    font-size: 12px;
                    color: #9b9b9b;
                    min-width: 4em;
                }
            }

            &__body {
                margin-left: 25px;
                background: #fafafa;
                padding: 4px 11px;
            }
        }
    }

    &__comment {
        &__title {
            padding: 1em;
            font-size: 12px;
            color: #9b9b9b;
            border-bottom: 1px #f4f4f4 solid;
        }

        &__item {
            margin-top: 20px;

            &__head {
                margin-bottom: 12px;

                &__ava {
                    width: 30px;
                    height: 30px;
                    margin-right: 12px;
                }

                &__name {
                    flex-grow: 1;
                    font-size: 14px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 1;
                    -webkit-box-orient: vertical;
                }

                &__showtime {
                    font-size: 12px;
                    color: #9b9b9b;
                }
            }

            &__body {
                display: flex;
                align-items: flex-start;

                &__left {
                    font-size: 14px;
                    color: #be3f41;
                    min-width: 3em;
                    width: 3em;
                }

                &__content {
                    flex: 1;

                    &__top {
                        color: #262626;
                        font-size: 16px;
                        background: #fafafa;
                        padding: 4px 11px;
                    }

                    &__replys {
                        font-size: 12px;
                        margin-bottom: 6px;
                    }
                }
            }
        }
    }
}

.mytuanlist {
    min-height: 100vh;
    padding: 30px 18px 180px;

    @media (max-width: 576px) {
        padding: 20px 0;
    }

    header {
        margin-bottom: 32px;
    }

    &__title {
        font-size: 20px;
        font-weight: bold;
        color: rgb(69, 69, 69);

        div {
            margin-right: 1rem;
        }
    }

    &__item {
        z-index: 1;
        border-bottom: 1px solid #f1f1f1;
        padding: 0 30px;
        flex-wrap: wrap;

        @media (max-width: 660px) {
            padding: 0 10px;
        }

        &:hover {
            background: #f8f8f8;
        }

        &__stickySpan {
            color: white;
            position: absolute;
            top: 0;
            left: 0;
            border-radius: 0px 0px 6px 0px;
            background: #FF9A27;
            padding: 1px 6px;
            font-size: 12px;
        }

        &__title {
            color: #454545;
            font-size: 16px;
            font-weight: 600;
            word-break: break-all;
            margin-bottom: .3em;
        }

        &__status {
            font-size: 12px;
            display: flex;
            align-items: center;
            flex-wrap: wrap;
            line-height: 2;
            color: #B2B2B2;

            &__icon {
                min-width: 4em;
                font-size: 12px;
                // line-height: 1;
                padding: 2px 12px;
                color: $red;
                border-radius: 12px;
                margin-right: 1em;
                border: 1px solid;
            }
        }

        &__textLink {
            @extend .mytuanlist__item__status__icon;
            border: 1px transparent solid;
            box-sizing: border-box;
            color: #999;

            &:hover {
                @extend .mytuanlist__item__status__icon;
            }
        }

        &__menu-button {
            // z-index: 1;

            button {
                // margin: 2px;
                background: transparent;
                box-sizing: border-box;
                font-size: 12px;
                position: relative;

                &:hover {
                    color: $red;
                }
            }
        }

        &__menu {
            position: absolute;
            background: white;
            padding: 8px;
            border-radius: 8px;
            border: 1px solid #f1f1f1;
            right: 0;
            top: 2.6em;
            font-size: 12px;
            z-index: 20;
            width: 195px;

            &__item {
                margin-bottom: 4px;
                padding: 8px 11px;
                font-size: 14px;
                user-select: none;
                color: #666;
                border: 0;
                // text-align: right;
                cursor: pointer;

                &_hover:hover {
                    background: #F8F5F5;
                    color: #333;
                }
            }
        }

        &__shareButton {
            color: $red;
            margin-right: 1em;
        }

        &__sharePanel {
            width: 500px;

            &__link {
                margin: 24px 0;

                span {
                    width: 8em
                }

                input {
                    flex: 1
                }
            }

            &__type {
                padding: 20px;
                width: 45%;
                border-radius: 7px;
                border: 1px solid rgba(217, 217, 217, 1);

                p {
                    font-size: 12px;
                    margin-bottom: 0;
                }

                h3 {
                    font-weight: 500;
                    color: inherit;
                }

                &_active {
                    color: $red;
                    border-color: $red;
                    background: $lightred;
                }
            }
        }

    }
}

.edittuan {
    max-width: 780px;
    position: relative;
    width: 100%;
    min-width: 300px;
    margin: 0 auto;
    padding-top: 90px;

    &__top {
        padding: 24px 20px;
        border-bottom: #f1f1f1 solid 2px;
        margin-bottom: 10px;
        position: fixed;
        background: #fff;
        width: 100%;
        z-index: 99;
        min-width: 300px;
        max-width: 780px;
        top: 50px;
        // width: calc(100% - 500px);

        &__title {
            flex: 1;
            color: #454545;
            font-size: 18px;
        }
    }

    &__wrap {
        overflow-y: auto;
        // max-height: 90vh;
        padding: 10px;
        padding-bottom: 90px;
    }

    // ::-webkit-scrollbar{
    //     width: 0!important;
    // }
    // -ms-overflow-style: none; 
    // overflow: -moz-scrollbars-none;

    &__panel {
        background: rgba(255, 255, 255, 1);
        box-shadow: 0px 0px 12px 0px rgba(219, 219, 219, 0.5);
        padding: 30px;
        margin-bottom: 20px;

        &__title {
            color: #454545;
            font-size: 20px;
            margin-bottom: 12px;
        }
    }

    &__switchitem {
        padding: 14px;
        // border: 1px solid #d1d1d1;
        margin-bottom: 14px;
        box-shadow: 0px 0px 12px 0px rgba(219, 219, 219, 0.5);
        &__text {
            color: #353535;
            font-size: 14px;
        }
    }

    &__content {
        &__top {
            border-bottom: #f1f1f1 solid 2px;

            &__ava {
                width: 36px;
                height: 36px;
                border-radius: 50%;
            }

            &__input {
                font-size: 24px;
                margin: 20px;
                flex: 1;
                border: 0;
                color: #353535;
            }
        }
    }

    &__goods {
        transition: all 0s;
        position: relative;

        &__item {
            width: 33%;
            flex-direction: column;
            padding: 12px;
            border: 1px solid #f1f1f1;
            font-size: 14px;
            margin-bottom: 16px;
            min-height: 250px;
            position: absolute;
            &__logo {
                width: 100%;
                height: 120px;
                margin-bottom: 12px;
            }

            &__title {
                width: 100%;
                text-align: left;
                font-size: 16px;
                color: #212121;
            }

            &__price {
                color: #FD5755;
            }

            &__menu {
                position: absolute;
                background: white;
                border-radius: 4px;
                border: 1px solid #f1f1f1;
                padding: 6px;

                &__item {
                    min-width: 4em;
                    padding: 4px;

                    &:hover {
                        background: #FD5755;
                        color: white;
                        cursor: pointer;
                    }
                }
            }

            &_place {
                border: 0;
                order: 99999
            }
        }
    }

    &__distri-setting {
        &__salemodel {
            color: #666;
            font-size: 14px;
            padding: .7em 1em;
            background: #eee;
            width: 9em;
            text-align: center;
            cursor: pointer;
            user-select: none;
            margin-right: 1em;
            box-sizing: border-box;
            border: #eee 1px solid;

            &_active {
                color: #FD5755;
                background: #fff;
                border: 1px #FD5755 solid;
            }
        }

        &__intro {
            color: #999;
            font-size: 12px;
            margin: 1em 0;
            transition: all 0s;
        }

        &__tobatch {
            color: var(--theme-main);
            font-weight: bold;
            font-size: 16px;
            margin-top: .6em;
            cursor: pointer;
        }

        &__batch {
            margin-top: .6em;
            background: #eee;
            position: relative;
            // padding: .4em;
            // animation: pulldown .2s linear forwards;

            &__selected-icon {
                width: 16px;
                height: 16px;
                border-radius: 50%;
                border: 1px solid #999;
                position: relative;
                box-sizing: border-box;
                margin-right: .6em;

                &_active {
                    background: white;
                    border: #FD5755 1px solid;

                    &::after {
                        content: '';
                        display: block;
                        background: #FD5755;
                        border-radius: 50%;
                        width: 8px;
                        height: 8px;
                        position: absolute;
                        left: 3px;
                        top: 3px;
                    }
                }
            }

            &__buttons {
                position: absolute;
                bottom: .4em;
                right: .4em;
            }

            &__button {
                background: transparent;
                margin-left: .4em;
            }
        }

        &__attr {
            border: 1px solid #cecece;
            margin-top: 12px;
            width: 100%;
            display: flex;
            padding: 12px;

            *:focus {
                box-shadow: none !important;
            }

            &__name {
                width: 100%;
                color: #212121;
                font-weight: 700;
                font-size: 14px;
            }

            &__unit {
                font-size: 12px;
                color: #8b8b8b;
            }
        }
    }

    &__address {
        padding-bottom: 1em;
        cursor: pointer;

        &-item {
            align-items: flex-start;
        }

        &-add {
            background: #fff;
            color: $red;
            border: 1px $red solid !important;
            border-radius: 4px;
            margin-bottom: 1em;
        }

        &-title {
            color: #454545;
            font-size: 16px;
            margin-bottom: .6em;
        }

        &-detail {
            font-size: 14px;
            color: #999;
        }

        &-ctrl {
            min-width: 8em;

            button {
                color: #a6a6a6;
                cursor: pointer;
                font-size: 14px;
                background: transparent;
                border: none;
            }
        }

        &__editpanel {
            cursor: auto;
            position: relative;
            border: 1px solid rgba(204, 204, 204, 1);
            padding: 12px 20px;

            &__input {
                margin: .2em 0;

                &-name {
                    font-size: 14px;
                    min-width: 6em;
                    color: #454545;
                }

                &-value {
                    font-size: 12px;
                    flex: 1;

                    input {
                        border: none;
                        width: 100%;
                        box-shadow: none !important;
                    }
                }
            }

            &__ctrl {
                position: absolute;
                z-index: 100;
                bottom: 12px;
                right: 20px;
            }
        }
    }

    &__sign {
        &__item {
            td {
                padding-top: 1em;
                padding-bottom: 1em;

                input {
                    border: 0;
                    background: #f8f8f8;
                }

                &:nth-child(2) {
                    padding-left: 10px;
                }
            }

            &__type {
                padding-left: 20px;
                border-right: 1px solid #d8d8d8;
                width: 5em
            }

            &__info-title {
                color: #666;
                width: 7em;
            }

            &__info-confirm {
                color: $red;
                user-select: none;
            }

            &__icon {
                border-radius: 2px;
                border: 1px solid $red;
                padding: 2px 6px 2px;
                font-size: 14px;
                line-height: 1;
                margin-right: 12px;
                color: $red;
            }
        }

        &__add {
            margin: 0 auto;
            display: inline-block;
            border-radius: 4px;
            border: 1px solid rgba(204, 204, 204, 1);
            color: #353535;
            background: transparent;
            position: relative;
            padding: 4px 11px;
            margin-top: 1rem;

            &__menu {
                background: white;
                position: absolute;
                outline: none;
                width: 6em;
                bottom: 0;
                left: 100%
            }
        }
    }

    &__tag {
        &__type {
            margin: 6px -5px;
            justify-content: flex-start;

            .iconfont {
                margin-right: 12px;
            }
        }

        &__item {
            color: #999;
            padding-left: 2em;
            justify-content: flex-start;
            font-size: 14px;

            .iconfont {
                margin-right: 12px;
            }
        }

        &__add {
            margin: 0 -5px 1em;
            font-size: 14px;
        }

        &__edit {

            &>div {
                height: 450px;
                flex-basis: 45%;
            }

            label {
                display: block;

                input {
                    width: 100%;
                    padding: 4px 11px;
                    font-size: 12px;
                }
            }

            &__fanlist {
                height: 350px;
                border: #eee solid 1px;
                overflow-y: auto;

                &__item {
                    padding: 4px;
                    margin: 4px;

                    div {
                        flex: 1;
                        font-size: 16px;
                        line-height: 1;
                    }

                    img {
                        width: 24px;
                        height: 24px;
                        border-radius: 50%;
                        margin-right: 12px;
                    }
                }
            }

        }
    }
}

a.ant-calendar-ok-btn-disabled {
    color: rgba(0, 0, 0, 0.25) !important;
}

.ant-calendar-ok-btn {
    color: #fff !important;
}

.ant-calendar-time-picker-select::-webkit-scrollbar {
    width: 0
}

body {
    counter-reset: page;
}
.page-count:after {
    counter-increment: page;
    content: "Page " counter(page) " of ";
}