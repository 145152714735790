@import '../options.scss';

.button{
    @media print {
        display: none;
    }
    @extend .cur-pointer;
    transition: opacity .3s;
    border-radius: 4px;
    box-sizing: border-box;
    &:hover{
        opacity: .5;
    }

    &_big{
        font-size: 16px;
        padding: 9px 22px;
    }

    &_solid{
        &_red{
            @extend .button;
            background: $red;
            color: #fff;
            border: $red solid 1px !important;
        }
        &_gray{
            @extend .button;
            background: $gray;
            color: #fff;
            border: $gray solid 1px !important;
        }
    }
    &_hollow{
        background: #fff;
        &_red{
            @extend .button;
            @extend .button_hollow;
            color: $red;
            border: $red solid 1px !important;
        }
        &_gray{
            @extend .button;
            @extend .button_hollow;
            color: $gray;
            border: $gray solid 1px !important;
        }
    }

}