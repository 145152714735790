@import './options.scss';

.goodlist {
    display: flex;
    flex-direction: column;
    height: 100vh;
    max-height: 100vh;
    max-width: 1200px;
    width: 100%;
    margin: 0 auto;

    &__category {
        max-height: 600px;
        overflow-y: auto;
        padding: 0 8px;
        &__item {
            margin-bottom: 6px;
            border: 1px solid transparent;
            border-radius: 6px;
            padding: 14px;

            &__name {
                width: 6em;
                min-width: 6em;
                color: #454545
            }

            &_active {
                border: 1px solid rgba(204, 204, 204, 1);
            }

            &__input {
                flex: 1;
                min-width: 7em;
                width: 7em;
                height: 1.6em;
                margin-left: 1em;

                input {
                    width: inherit;
                    border: 0;
                    box-shadow: none !important;
                    outline: unset;
                    color: black;
                }
            }
        }
    }
}

.goodedit {
    max-width: 780px;
    width: 100%;height: 100vh;
    min-width: 300px;
    margin: 0 auto;
    // padding: 120px 0;
    // position: relative;
    display: flex;
    flex-direction: column;

    &__panel {
        box-shadow: 0px 0px 12px 0px rgba(219, 219, 219, 0.5);
        padding: 30px;
        margin-bottom: 30px;

        &__input {
            margin-bottom: 12px;

            &__name {
                min-width: 6em;
            }

            &__value {
                flex: 1;
                position: relative;
                input {
                    border: 1px solid rgba(235, 235, 235, 1);
                    font-size: 12px;
                    padding: 0.7em;
                    width: 100%;
                }
                &:hover{
                    .icon-guanbi-tianchong{
                        display: block;
                    }
                }

                .icon-guanbi-tianchong{
                    display: none;
                    position: absolute;
                    right: 5px;
                    top: 8px;
                    line-height: 1;
                    font-size: 20px;
                }
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    &__top {
        border-bottom: 1px #F1F1F1 solid;
        padding: 20px;
        background: #fff;
        // position: absolute;
        width: 100%;
        top: 0;
        z-index: 20;

        &__back {
            color: #828282;
            font-size: 20px;
            line-height: 20px;
        }

        &__title {
            flex: 1;
            font-weight: 500;
            color: rgba(69, 69, 69, 1);
            font-size: 24px;
            text-align: center;
        }

        &__save {
            button {
                border-radius: 8px;
                border: 0;
                background: $red;
                color: white;
            }
        }
    }

    &__content{
        flex: 1;
        overflow-y: auto;
        padding: 20px;
    }
    ::-webkit-scrollbar{
        width: 0;
    }
}

.goodItem {
    $imgSize: 215px;

    display: flex;
    flex-direction: column;
    background: #fff;
    width: $imgSize;
    box-shadow: 0px 2px 10px 0px rgba(215, 215, 215, 0.5);
    margin-bottom: 1rem;

    position: relative;

    img {
        width: $imgSize;
        height: $imgSize;
    }

    &__data {
        padding: 9px;

        &__name {
            color: #333;
        }

        &__number {
            margin-top: .6rem;
            font-size: .7rem;
            color: #999;
        }

        &__tag {
            margin-top: .6rem;
            padding: 1px 3px;
            border-radius: 2px;
            border: 1px solid #fff;
            display: inline-block;
            font-size: .7rem;
            margin-right: 3px;

            &_orange {
                border-color: #FF9A27;
                color: #FF9A27;
            }

            &_gray {
                border-color: #999;
                color: #999;
            }

            &_super {
                background: linear-gradient(117deg, rgba(98, 98, 98, 0.77) 0%, rgba(51, 51, 51, 1) 100%);
                color: #FFE5C6;
            }
        }

        &__price {
            color: #333;
            font-size: 1.2rem;

            span:nth-child(1) {
                font-size: .7rem;
            }
        }
    }

    &:hover {
        box-shadow: 0px 2px 10px 0px rgba(215, 215, 215, 1);
    }

    &__hover {
        background: rgba(255, 255, 255, .5);
        width: $imgSize;
        height: $imgSize;
        position: absolute;
        top: 0;
        left: 0;

        div{
            position: absolute;
            bottom: 2rem;
            width: 100%;
        }

        &__button {
            font-size: 1.1rem;
            border: 0;
            padding: 4px 30px;
            font-weight: 600;
            &_static{
                color: #999;
                border-radius: 1.5rem;
                background: #fff;
            }
            &_active{
                color: #FFE5C6;
                border-radius: 1.5rem;
                background:linear-gradient(117deg,rgba(98,98,98,0.77) 0%,rgba(51,51,51,1) 100%);
                i{
                    margin-right: .6rem;
                }
            }
        }
    }
}