/* flex布局 */
.flex-row {
  display: flex;
  flex-direction: row;
  -webkit-flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
  -webkit-flex-direction: column;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.flex-center {
  align-items: center;
  justify-content: center;
}

.flex-place {
  flex: 1;
}
/* 字重样式 */
.regular {
  font-weight: 400;
  font-weight: normal;
}

.medium {
  font-weight: 500;
  font-weight: bold;
}

.semibold {
  font-weight: 600;
  font-weight: bolder;
}
