$red: #FA503C;
$lightred: #FFEEEF;
$gray: #D1D1D1;

@mixin hover-class {
    background: $lightred;
    color: $red;
}

:root {
    --theme-main: #FA503C;
}