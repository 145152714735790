.c33 {
    color: #333;
}

.c99 {
    color: #999;
}

.cff {
    color: #fff;
}