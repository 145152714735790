#login {
    position: relative;
    color: #333;
    width: 290px;
    height: 320px;
    box-sizing: border-box;
    padding: 20px;

    i {
        color: #666;
        font-size: 24px;
        line-height: 1;
        position: absolute;
        right: 20px;
        top: 20px
    }

    img {
        width: 150px;
        height: 150px;
        margin-top: 20px;
        margin-bottom: 20px
    }

    @media (max-width: 576px) {
        width: 100vw;
        height: 100vh
    }
}