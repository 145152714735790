@import '../../../style/flex.scss';
.search-nav {
  background-color: #f5f5f5;
  border-radius: 5px;
  margin-bottom: 12px;
  .nav-input {
    background-color: inherit;
    border: none;
    flex: 1;
    font-size: 14px;
    color: #333;
    padding: 8px 0;
    text-indent: 16px;
  }
  .nav-iocn {
    padding-right: 16px;
    cursor: pointer;
    .icon-img {
    }
  }
}
