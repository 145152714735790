@import './options.scss';
@import './base/button.scss';
@import './base/animation.scss';
@import './color.scss';

body{
    height: unset;
}

.iconfont {
    user-select: none;
}

.fcc,.flex-center {
    display: flex;
    justify-content: center;
    align-items: center;
}

.fbc, .flex-between {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.fc, .flex-box {
    display: flex;
    align-items: center;
}
.flex-top{
    align-items: flex-start !important;
}
.flexwrap{
    flex-wrap: wrap;
}
.flex1{
    flex: 1
}

* {
    text-decoration: none;
    // font-family: Helvetica, Arial, sans-serif;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", 
    "Helvetica Neue", "PingFang SC", "Noto Sans", "Noto Sans CJK SC", "Microsoft YaHei", "微软雅黑", 
    sans-serif;
    // transition: all .3s;
}

span {
    color: inherit;
    font-size: inherit;
}

*>div {
    font-size: inherit;
}

.mask {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    background: #66666666;
    z-index: 99;
    // animation: maskFadeIn 0.3s linear forwards;
    
    .maskinner {
        box-shadow: #999 0 0 15px;
        max-width: 50%;
        position: relative;

        @media (max-width: 576px) {
            max-width: 95%;
        }

        @media (max-width: 1280px) {
            max-width: 80%;
        }
    }
}

#contentWrap {
    margin: 0 auto;
    transition: all 0.3s;
    min-height: calc(100vh - 50px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    flex: 1;
}

.selectPanel {
    animation: menuOpen .3s ease 0s forwards;

    div {
        min-height: 40px;

        div {
            display: flex;
            align-items: center;
            padding-left: 10%;
        }
    }
}

.select-hover:hover {
    background: $red;
    color: #fff;

    * {
        color: #fff;

    }
}

// .hover-text-white{

// }

input {
    outline: none;
}

input,
button {
    border-style: solid;
    border-width: 1px;
    outline: none;
    cursor: pointer;
}

a {
    &:hover {
        text-decoration: none
    }
}

@media (max-width: 576px) {
    .sm-hide {
        display: none
    }
}

.img-wrap {
    border-radius: 50%;
    overflow: hidden;
    display: inline-flex;
    justify-content: center;
    align-items: center;

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.icon-xingzhuang::before {
    animation: rotate 1s infinite linear;
    display: block
}

@media print {
    #footer {
        display: none
    }
}
@page {
    margin: 1cm 0;
    font-family: SimSun,FangSong,STSong,STZhongsong,Microsoft YaHei, !important;

}


.img-center {
    object-fit: cover;
    width: inherit;
    height: inherit;
}

.single-text-hidden {
    overflow: hidden;
    word-break: break-all;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.hide-scrollbar {
    &::-webkit-scrollbar {
        width: 0
    }
}

.fixedBG {
    position: fixed;
    width: 100vw;
    height: 100vh;
    z-index: -2;
    top: 0;
    left: 0;
}

.cur-pointer {
    cursor: pointer;
}

.shadow-menu {
    -webkit-box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    z-index: 999;
    padding: 4px 0;

    &__item {
        padding: 5px 12px;
        font-size: 14px;
        color: rgba(0, 0, 0, .65);

        &:hover {
            background: $lightred;
        }
    }

    animation: menuOpen .3s ease forwards;
}

.skeleton {
    height: 1rem;
    background: linear-gradient(90deg, #f2f2f2 25%, #e6e6e6 50%, #f2f2f2 75%);
    animation: skeleton 4s linear infinite;
    background-size: 400%;
}

.PageContent {
    display: flex;
}

.disableInput{
    background: #f2f2f2;
    padding: 4px 11px;
    border: 0;
    border-radius: 7px;
    &:hover{
        box-shadow: transparent;
    }
}

.flex-column{
    display: flex;
    flex-direction: column;
    align-items: center;
    & > * {
        width: 100%;
    }
}

.hiddenScrollBar{
    &::-webkit-scrollbar {
        width: 2px;
        /* // background: #fd5356 */
    }

    &:hover::-webkit-scrollbar-thumb {
        background: #ccc;
        border-radius: 5px
    }
}