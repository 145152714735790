#payment {
    padding-top: 40vw;

    @media (min-width:1000px) {
        padding-top: 400px
    }

    position: relative;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 100vh;

    .type {
        border: 4px solid transparent;
        box-sizing: border-box;

        @media (min-width:1000px) {
            width: 30%;
            min-height: 300px
        }

        @media (max-width:1000px) {
            width: 45%;
            min-height: 200px
        }

        &.checked {
            border: 4px solid var(--theme-main);
        }
    }

    .title {
        font-size: 44px;
        color: #2c2c2c;
        margin: 40px;
        margin-bottom: 0;

        @media (max-width: 700px) {
            font-size: 28px;
            margin: 0 20px;
        }
    }

    .power {
        padding: 50px;
        padding-bottom: 0;

        @media (max-width: 700px) {
            padding: 20px;
        }

        &>div {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            flex-basis: 33%;

            @media (max-width: 700px) {
                flex-basis: 50%;
                margin-bottom: 20px
            }

            margin-bottom: 60px;

            &>div:nth-child(1) {
                width: 50px;
                min-width: 50px;
                height: 50px;

                @media (max-width: 700px) {
                    width: 30px;
                    min-width: 30px;
                    height: 30px;
                }

                background: var(--theme-main);
                border-radius: 50%;

                img {
                    width: 30px;
                    height: 30px;
                    min-width: 30px;

                    @media (max-width: 700px) {
                        width: 15px;
                        min-width: 15px;
                        height: 15px;
                    }
                }
            }

            &>div {
                font-size: 24px;

                @media (max-width: 700px) {
                    font-size: 14px;
                }

                margin: 10px
            }
        }
    }

    table {
        width: 100%;
        box-shadow: 0 8px 19px 0 rgba(219, 219, 219, 0.50);
        table-layout: fixed;
    }

    td {
        padding: 4px 11px;
        font-size: 14px;
        line-height: 40px;

        @media (max-width: 576px) {
            line-height: 20px;
        }
    }

    td:nth-child(1) {
        min-width: 9em;
        // width: 20%
    }

    thead {
        background: #f3f3f3;
    }

    .tableWrap {
        padding: 50px;

        @media (max-width: 576px) {
            padding: 10px
        }
    }
}