@import './options.scss';

.aliGoodList {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.aliControl {
    margin-bottom: 1rem;
    &__search {
        padding: 40px;
        display: flex;
        align-items: center;

        span {
            font-size: .9rem;
            font-weight: 600;
            color: $red;
            padding: 0 20px;
        }

        input,
        button {
            font-size: .9rem;
            border: 0;
            border-radius: 2px;
        }

        input {
            padding: 4px 11px;
            background: #EDEDED;
            width: 22rem;
        }

        button {
            background: $red;
            color: #fff;
            transition: all .3s;

            &:hover {
                opacity: .6;
            }
        }
    }

    &__price {
        font-size: 12px;
        display: flex;
        align-items: center;
        margin-right: 1rem;
        label{
            position: relative;
            // display: block;
            width: 4.5rem;
            box-sizing: border-box;
            span{
                left: 0;
                top: 1px;
                position: absolute;
                padding: 4px;
            }
        }
        &>span{
            margin: 0 4px;
        }
        input {
            padding: 4px 4px;
            padding-left: 1.5rem;
            border: 1px solid rgba(151, 151, 151, 1);
            width: inherit;
        }
        button{
            border: 0;
            margin-left: 2px;
            background: $red;
            color: #fff;
            transition: all .3s;
            border-radius: 2px;
            &:hover{
                opacity: .5;
            }
        }
    }

    &__deliveryTimeType{
        display: flex;
        align-items: center;
        button{
            border: 0;
            margin-left: 2px;
            background: $red;
            color: #fff;
            transition: all .3s;
            border-radius: 2px;
            &:hover{
                opacity: .5;
            }
        }
    }

    &__category{
        display: flex;
        flex-wrap: wrap;
        &__item{
            width: 7rem;
            padding: .5rem .2rem;
            text-align: center;
            position: relative;
            &_active{
                @include hover-class;
            }
            &:hover{
                @include hover-class;
            }
            &__menu{
                box-shadow: 0px 0px 12px 0px rgba(219, 219, 219, 0.5);
                top: 100%;
                border: 1px solid #ccc;
                position: absolute;
                // width: 100%;
                background: #fff;
                padding: 4px;
                z-index: 10;
                // border-radius: 8px;
                max-height: 600%;
                overflow-y: auto;
                animation: menuOpen forwards linear  .2s ;
            }
        }
    }
}

.aliRefundList{
    padding: 200px 20px;
}

.aliRefundItem{
    table{
        margin-bottom: 1rem;
        width: 100%;
    }
    td{
        border: 1px solid #ddd;
        padding: 4px ;
    }
}

