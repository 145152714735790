#member_after_pay {
    .top {
        background: #fd5356;
        padding: 60px 30px 0;
        overflow: hidden;

        .main {
            background-image: linear-gradient(-90deg, #4c4c4c 0%, #1d1810 100%);
            box-shadow: 0 5px 10px 1px #16130d;
            border-radius: 8px 8px 0 0;
            padding: 8px 8px 0;

            .content {
                border: 1px solid #e1c482;
                border-radius: 13px 13px 0 0;
                min-height: 60px;
                border-bottom: 0;
                display: flex;
                align-items: center;
                padding: 20px 10px 10px;

                .img-wrap {
                    width: 48px;
                    height: 48px;
                    min-width: 48px;
                    border: white 2px solid
                }

                .name_and_time {
                    * {
                        color: #e1c482;
                    }

                    display: flex;
                    flex-direction: column;
                    margin-left: 10px;

                    div:nth-child(2) {
                        font-size: 14px;
                    }
                }
            }
        }
    }

    .title {
        font-weight: bold;
        margin: 10px
    }

    .icon {
        background-image: linear-gradient(-90deg, #ff898f 0%, var(--theme-main) 100%);
        border-radius: 50%;
        width: 32px;
        height: 32px;
        min-width: 32px;
        min-height: 32px;
        margin-right: .6em;

        img {
            max-width: 16px;
        }

    }

    .power {
        padding: 20px;
        display: flex;
        flex-wrap: wrap;

        &>div {
            flex-basis: 50%;
            margin-bottom: 20px;

            .value {
                color: #8f8f8f;
                font-size: 12px;
                padding-right: 1.3em;
                margin-top: .6em
            }

            &>div {
                display: flex;
                align-items: center;

                * {
                    font-size: 14px;
                }
            }
        }
    }
}