$red: #fd5356;
$lightred: #fffdfd;

#index {
    margin-right: auto;
    transition: all .3s;

    display: flex;
    flex-direction: column;
    min-height: 100vh;

    .login {
        background: #ffffff;
        border-radius: 20px;
        width: 117px;
        height: 40px;
        border: none;
        color: $red;
        font-size: 18px
    }
}